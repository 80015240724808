<template lang="">
  <ag-charts-vue :options="options"></ag-charts-vue>
</template>
<script>
import { AgChartsVue } from 'ag-charts-vue';

export default {
  components: {
    'ag-charts-vue': AgChartsVue,
  },
  props: {
    data: { type: Array, required: true },
  },
  data: function () {
    return {
      options: null,
    };
  },
  created() {
    this.options = {
      title: {
        text: 'Akzeptiert Verteilung',
      },
      data: this.data,
      series: [
        {
          type: 'bar',
          xKey: 'quarter',
          yKey: 'technischeAbfragestoerung',
          yName: 'Tech. Abfragestörung',
          stacked: true,
        },
        {
          type: 'bar',
          xKey: 'quarter',
          yKey: 'reiseAusgebucht',
          yName: 'Reise ausgebucht',
          stacked: true,
        },

        {
          type: 'bar',
          xKey: 'quarter',
          yKey: 'cmWechsel',
          yName: 'CM-Wechsel',
          stacked: true,
        },

        {
          type: 'bar',
          xKey: 'quarter',
          yKey: 'nichtAkzeptiert',
          yName: 'Nicht akzeptiert',
          stacked: true,
        },
      ],
      legend: {
        position: 'right',
      },
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style lang=""></style>
