<template>
  <div class="card">
    <ag-charts-vue :options="options"></ag-charts-vue>
  </div>
</template>
<script>
import { AgChartsVue } from 'ag-charts-vue';
export default {
  components: {
    AgChartsVue,
  },
  props: {
    data: { type: Array, required: true },
    title: { type: String, default: '' },
  },
  data() {
    return {
      options: null,
    };
  },
  watch: {
    data(newData) {
      const options = { ...this.options };
      options.data = newData;
      this.options = options;
      options.series[0].innerLabels[0].text = this.percentage + '%';
    },
  },
  created() {
    this.options = {
      data: this.data,
      width: 300,
      height: 300,
      series: [
        {
          type: 'pie',
          angleKey: 'count',
          fills: this.percentage > 80 ? ['#35ab7c', '#dff3ea'] : ['#F39F5A', '#B4B4B3'],
          strokeWidth: 0,
          innerRadiusOffset: -15,
          title: {
            spacing: 30,
            text: this.title,
            showInLegend: false,
          },
          innerLabels: [
            {
              text: this.percentage + '%',
              fontSize: 34,
            },
            {
              text: 'bearbeitet',
              fontSize: 20,
            },
          ],
        },
      ],
      overlays: {
        noData: {
          text: '',
        },
      },
    };
  },
  computed: {
    total() {
      return this.data.reduce((sum, d) => sum + d.count, 0);
    },
    percentage() {
      const bearbeitete = this.data.find(item => item.name === 'Bearbeitet')?.count;
      return `${((bearbeitete / this.total) * 100).toFixed()}`;
    },
  },
};
</script>
